<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="健康宣教"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <vcell style="margin-top: 10px" >
            <div style="float: left;width: 30%">
                <vimg
                        width="100"
                        height="70"
                        src="https://img01.yzcdn.cn/vant/cat.jpeg"
                />
            </div>
            <div style="float: left;width: 70%;margin-top: 5px">
                <div class="text" style="text-align: center">
                    <span style="font-size: larger">相关疾病知识介绍</span>
                </div>
                <div class="text" style="text-align: center;margin-top: 5px">
                    <span style="font-size: small">健康知识知得多，病痛自然少得多</span>
                </div>
            </div>
        </vcell>
        <vcell style="margin-top: 10px" >
            <div style="float: left;width: 30%">
                <vimg
                        width="100"
                        height="70"
                        src="https://img01.yzcdn.cn/vant/cat.jpeg"
                />
            </div>
            <div style="float: left;width: 70%;margin-top: 5px">
                <div class="text" style="text-align: center">
                    <span style="font-size: larger">肩关节疾病自检操</span>
                </div>
                <div class="text" style="text-align: center;margin-top: 5px">
                    <span style="font-size: small">初步判断僵冻肩与肩袖损伤</span>
                </div>
            </div>
        </vcell>
        <vcell style="margin-top: 10px" >
            <div style="float: left;width: 30%">
                <vimg
                        width="100"
                        height="70"
                        src="https://img01.yzcdn.cn/vant/cat.jpeg"
                />
            </div>
            <div style="float: left;width: 70%;margin-top: 5px">
                <div class="text" style="text-align: center">
                    <span style="font-size: larger">手术示意视频</span>
                </div>
                <div class="text" style="text-align: center;margin-top: 5px">
                    <span style="font-size: small">相关描述</span>
                </div>
            </div>
        </vcell>
        <vcell style="margin-top: 10px" >
            <div style="float: left;width: 30%">
                <vimg
                        width="100"
                        height="70"
                        src="https://img01.yzcdn.cn/vant/cat.jpeg"
                />
            </div>
            <div style="float: left;width: 70%;margin-top: 5px">
                <div class="text" style="text-align: center">
                    <span style="font-size: larger">关节镜手术介绍视频</span>
                </div>
                <div class="text" style="text-align: center;margin-top: 5px">
                    <span style="font-size: small">相关描述</span>
                </div>
            </div>
        </vcell>
        <vcell style="margin-top: 10px" >
            <div style="float: left;width: 30%">
                <vimg
                        width="100"
                        height="70"
                        src="https://img01.yzcdn.cn/vant/cat.jpeg"
                />
            </div>
            <div style="float: left;width: 70%;margin-top: 5px">
                <div class="text" style="text-align: center">
                    <span style="font-size: larger">术后患者功能展示视频</span>
                </div>
                <div class="text" style="text-align: center;margin-top: 5px">
                    <span style="font-size: small">相关描述</span>
                </div>
            </div>
        </vcell>





        <empty v-if="nodata" description="查无数据" />

        <RLFooter/>
    </div>
</template>

<script>

    import {Cell as vcell,Empty,Image as vimg} from 'vant';
    import {getFollows} from '@/api/follow';

    export default {
        name: 'QueIdx',
        components: {
            vcell,Empty,vimg
        },
        data(){
            return {
                followcrfs:[],
                nodata:false
            };
        },
        computed:{

        },
        methods:{
            initidx(){
                console.info(this.$store.state.user);
                //this.$store.commit('updatePid',this.$route.params.pid);
                let query = this.$route.query;

                let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
                getFollows({follow_time:follow_time,pid:this.$route.params.pid}).then((response) => {
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.total;

                        this.followcrfs=response.data.rows;
                        console.log(response.data)

                        if(this.followcrfs.length==0){
                            this.nodata = true
                        }
                    }
                });
                // getqueidxs({pid:this.$route.params.pid}).then((response) => {
                //     this.loading=true;
                //     if(this.followcrfs.length==0){
                //         this.followcrfs=response.data;
                //     }
                // });
            },
            showQue(idx){
                this.$router.push({path: '/que/'+idx.id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            }
        }
        ,
        created() {
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
